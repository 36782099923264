<template>
  <div class="flex-grow-bokahem-1" @click="showServiceSelect()">
    <div class="header-bokahem-block-label">
      <span>{{ serviceSubtitle }}</span>
    </div>
    <div v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" :class="{'select_active': serviceSelectShow }"
         id="service-select-bokahem" class="header-bokahem-block header-bokahem-block-margin select border-left--none border-right--none select-js">
      <div class="select-heading-bokahem" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}">
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" id="select-service-bokahem-id" unselectable="on" class="select-current">
          {{ selectedService }}
        </span>
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" @click="clickOnService()" v-if="supplierServices.length == 0" unselectable="on" class="select-current">
          {{ servicePlaceholder }}
        </span>
      </div>
      <div class="select-body select-body-js" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}">
         <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" style="padding-left:3px;padding-top:0;" class="select-option-bokahem service-select-current"
                v-bind:key="serviceGroupKey"
               v-for="(serviceGroup,serviceGroupKey) in serviceList">
            <span style="font-size:15px">{{ serviceGroupKey }}</span>
            <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" class="select-option-bokahem service-select-current"
                  @click="selectService(serviceKey, service.name)" v-bind:key="serviceKey"
                  v-for="(service,serviceKey) in serviceGroup">
             {{ service.name }}
            </span>
         </span>
      </div>
      <svg class="icon-svg-bokahem">
        <TabletIcon></TabletIcon>
      </svg>
    </div>
  </div>
</template>

<script>

import {
  SET_HEADER_ACTIVE,
  SET_SERVICE_CLICKED
} from "@/store/mutations.type";

import {
  SELECT_RECURRENCE_OPTION,
  SELECT_SERVICE
} from "@/store/actions.type";

import TabletIcon from '@/assets/custom-svg/icons/tablet.svg';

export default {
  components: {
    TabletIcon
  },
  data() {
    return {
      supplierServices: [],
      serviceList: [],
      selectedService: null,
      serviceSelectShow: false
     }
  },
  computed: {
    backgroundPrimaryColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
    serviceSubtitle: {
      get() {
        if ('service_subtitle' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.service_subtitle;
        } else {
          return null;
        }
      }
    },
    servicePlaceholder: {
      get() {
        if ('service_placeholder' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.service_placeholder;
        } else {
          return null;
        }
      }
    }
  },
  watch: {
    '$store.state.serviceList': function() {
      this.serviceList = this.$store.state.serviceList;
      let defaultServiceId = 0;

      let defaultServiceKeyFromProperty = null;

      let query = this.$router.history.current.query;

      if ('service' in query && query.service != null) {
        defaultServiceKeyFromProperty = query.service;
      } else if ('service' in this.$root && this.$root.service != null) {
        defaultServiceKeyFromProperty = this.$root.service;
      }

      let setFromProperty = false;

      if (this.$store.state.supplierServices.length > 0) {

        this.$store.state.supplierServices.forEach((value, index) => {
          if (defaultServiceKeyFromProperty !== null
              && 'service_category_id' in value
              && defaultServiceKeyFromProperty == value.service_category_id) {
            defaultServiceId = index;
            setFromProperty = true;
          }
        });

        if (setFromProperty === false) {
          this.$store.state.supplierServices.forEach((value, index) => {
            if ('default_service_id' in value && value.default_service_id === true) {
              defaultServiceId = index;
              return;
            }
          });
        }
      }

      this.$store.dispatch(SET_SERVICE_CLICKED, false);

      if (this.selectedService === null) {
        if (defaultServiceId in this.$store.state.supplierServices) {
          this.selectedService = this.$store.state.supplierServices[defaultServiceId].name;
          this.$store.dispatch(SELECT_SERVICE, defaultServiceId);
        }
      }
      if (this.$store.state.supplierServices.length == 0) {
        this.selectedService = null;
        this.$store.dispatch(SELECT_RECURRENCE_OPTION, null);
      }
    },
    '$store.state.supplierServices': function() {
      this.supplierServices = this.$store.state.supplierServices;

      let defaultServiceId = 0;

      let defaultServiceKeyFromProperty = null;

      let query = this.$router.history.current.query;

      if ('service' in query && query.service != null) {
        defaultServiceKeyFromProperty = query.service;
      } else if ('service' in this.$root && this.$root.service != null) {
        defaultServiceKeyFromProperty = this.$root.service;
      }

      let setFromProperty = false;

      if (this.$store.state.supplierServices.length > 0) {

          this.$store.state.supplierServices.forEach((value, index) => {
            if (defaultServiceKeyFromProperty !== null
                && 'service_category_id' in value
                && defaultServiceKeyFromProperty == value.service_category_id) {
              defaultServiceId = index;
              setFromProperty = true;
            }
          });

        if (setFromProperty === false) {
          this.$store.state.supplierServices.forEach((value, index) => {
            if ('default_service_id' in value && value.default_service_id === true) {
              defaultServiceId = index;
              return;
            }
          });
        }
      }

      this.$store.dispatch(SET_SERVICE_CLICKED, false);

      if (this.selectedService === null) {
        if (defaultServiceId in this.$store.state.supplierServices) {
          this.selectedService = this.$store.state.supplierServices[defaultServiceId].name;
          this.$store.dispatch(SELECT_SERVICE, defaultServiceId);
        }
      }

      if (this.$store.state.supplierServices.length == 0) {
        this.selectedService = null;
        this.$store.dispatch(SELECT_RECURRENCE_OPTION, null);
      }
    }
  },
  methods: {
    clickOnService() {
      this.$store.dispatch(SET_SERVICE_CLICKED, true);
    },
    selectService(serviceKey, serviceName) {
      this.selectedService = serviceName;
      this.$store.dispatch(SET_HEADER_ACTIVE, true);
      this.$store.dispatch(SELECT_SERVICE, serviceKey);
    },
    showServiceSelect() {
      this.serviceSelectShow = this.serviceSelectShow === true ? false : true;
    }
  }
}
</script>