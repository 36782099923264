<template>
    <div v-if="campaignCode === null" class="row-bokahem" v-bind:style="{ color: textColor + ' !important'}">
      <div class="col-bokahem-6" >
        <div class="info-input-holder">
          <input 
            v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" 
            v-model="campaign_code"
            placeholder="Ange rabattkoden"
            type="text" 
                class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
          
        </div>
      </div>

      <div class="col-bokahem-6">
        <button v-bind:style="{ backgroundColor: primaryColor + ' !important'}"
          @click="applyCampaignCode()" style="height:43.19px !important;margin-top:0px;" class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem">
          Använd
        </button>
      </div>

      <div class="col-bokahem-12 mt-1" style="max-width:580px;padding-right:25px;padding-left:25px;">
        <span class="bokahem-valid-error" 
            v-bind:style="{ color: validationColor + ' !important'}" 
              v-if="submitted && campaignCode === null">
              <span v-if="campaignCodeMessage">
                {{ campaignCodeMessage }}
              </span>
              <span v-if="campaignCodeMessage === null">
                rabattkoden är inte giltig.
              </span>
          </span>
      </div>
    </div>
</template>

<script>
    import { APPLY_CAMPAIGN_CODE } from "@/store/actions.type";

    export default {
        methods: {
          applyCampaignCode() {
            if (this.campaign_code != null) {
              this.submitted = true;

              let params = {};

              params['code'] = this.campaign_code;
              params['service_id'] = this.$store.getters.selectedServiceId;
              params['postal_code'] = this.$store.getters.postalCode;
              params['start_date'] = this.$store.getters.gapStartDate;
              params['start_time'] = this.$store.getters.gapStartTime;
              params['end_time'] = this.$store.getters.gapEndTime;
              
              this.$store.dispatch(APPLY_CAMPAIGN_CODE, params).then(() => {
              });
            }
          },
        },
        data() {
          return {
            submit: false,
            campaign_code: null
          }
        },
        mounted() {
            let query = this.$router.history.current.query;
            
            if ('campaign' in query && query.campaign != null) {
                this.campaign_code = query.campaign;
                this.applyCampaignCode();
            }
        },
        watch: {
          '$store.state.campaignInitial': function() {
            this.submitted = false;
            this.campaign_code = null;
          }
        },
        computed: {
            supplierId() {
              return this.$store.getters.supplierId;
            },
            validationColor() {
              return this.$store.getters.validationColor;
            },
            primaryColor() {
              return this.$store.getters.primaryColor;
            },
            campaignInitial() {
              return this.$store.getters.campaignInitial;
            },
            backgroundSecondaryColor() {
              return this.$store.getters.backgroundSecondaryColor;
            },
            textColor() {
              return this.$store.getters.textColor;
            },
            campaignCodeMessage() {
              return this.$store.getters.campaignCodeMessage;
            },
            campaignCode() {
              return this.$store.getters.campaignCode;
            },
            campaignCodeDiscount() {
              return this.$store.getters.campaignCodeDiscount;
            },
            campaignCodeTerms() {
              return this.$store.getters.campaignCodeTerms;
            }
        }
    }
</script>
