<template>
    <div class="container-bokahem">
        <div class="row-bokahem btn-bokahem-row-group">
            <div class="col-bokahem-12 mb-1">
                <button 
                    :disabled="clicked"
                    type="button" @click="finishCheckout()"
                        v-bind:style="{backgroundColor: primaryColor + ' !important', opacity: clicked ? 0.6 : 1 }"
                        style="background-color: #23A994 !important;color:#FFFFFF"
                        class="btn-bokahem btn-bokahem-primary-button text-button-color border-rounded-bokahem btn-bokahem-lg font-weight-semi-bokahem">
                  BEKRÄFTA BESTÄLLNING
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {FINISH_CHECKOUT} from "@/store/actions.type";
    import {SET_IS_LOADING} from "@/store/mutations.type";
    export default {
        methods: {
          finishCheckout() {

            this.$store.dispatch(SET_IS_LOADING, true);

            if (this.$store.getters.isLoading === true) {
          
                this.clicked = true;

                let params = {};

                let bookingData = {};

                if (this.$store.getters.serviceGroupId == 2) {
                bookingData[this.$store.getters.widgetFields.corder_company_name.field_id] =
                    this.$store.getters.checkoutData.order.order_company_name;
                bookingData[this.$store.getters.widgetFields.corder_first_name.field_id] =
                    this.$store.getters.checkoutData.order.order_first_name;
                bookingData[this.$store.getters.widgetFields.corder_last_name.field_id] =
                    this.$store.getters.checkoutData.order.order_last_name;
                bookingData[this.$store.getters.widgetFields.corder_email.field_id] =
                    this.$store.getters.checkoutData.order.order_email;
                bookingData[this.$store.getters.widgetFields.corder_phone.field_id] =
                    this.$store.getters.checkoutData.order.order_phone;
                bookingData[this.$store.getters.widgetFields.corder_organisation_number.field_id] =
                    this.$store.getters.checkoutData.order.order_organisation_number;
                bookingData[this.$store.getters.widgetFields.corder_sms.field_id] =
                    this.$store.getters.checkoutData.order.order_sms;
                bookingData[this.$store.getters.widgetFields.corder_type_appartment.field_id] =
                    this.$store.getters.checkoutData.order.order_type_appartment;
                bookingData[this.$store.getters.widgetFields.corder_additional_appartment_info.field_id] =
                    this.$store.getters.checkoutData.order.order_additional_appartment_info;
                bookingData[this.$store.getters.widgetFields.corder_key_handling.field_id] =
                    this.$store.getters.checkoutData.order.order_key_handling;
                bookingData[this.$store.getters.widgetFields.corder_mission_description.field_id] =
                    this.$store.getters.checkoutData.order.order_mission_description;

                } else {
                bookingData[this.$store.getters.widgetFields.order_first_name.field_id] =
                    this.$store.getters.checkoutData.order.order_first_name;
                bookingData[this.$store.getters.widgetFields.order_last_name.field_id] =
                    this.$store.getters.checkoutData.order.order_last_name;
                bookingData[this.$store.getters.widgetFields.order_email.field_id] =
                    this.$store.getters.checkoutData.order.order_email;
                bookingData[this.$store.getters.widgetFields.order_phone.field_id] =
                    this.$store.getters.checkoutData.order.order_phone;
                bookingData[this.$store.getters.widgetFields.order_rut.field_id] =
                    this.$store.getters.checkoutData.order.order_rut;
                bookingData[this.$store.getters.widgetFields.order_sms.field_id] =
                    this.$store.getters.checkoutData.order.order_sms;
                bookingData[this.$store.getters.widgetFields.order_type_appartment.field_id] =
                    this.$store.getters.checkoutData.order.order_type_appartment;
                bookingData[this.$store.getters.widgetFields.order_additional_appartment_info.field_id] =
                    this.$store.getters.checkoutData.order.order_additional_appartment_info;
                bookingData[this.$store.getters.widgetFields.order_key_handling.field_id] =
                    this.$store.getters.checkoutData.order.order_key_handling;
                bookingData[this.$store.getters.widgetFields.order_mission_description.field_id] =
                    this.$store.getters.checkoutData.order.order_mission_description;
                bookingData[this.$store.getters.widgetFields.order_personal_number.field_id] =
                    this.$store.getters.checkoutData.order.order_personal_number;
                }

                let deliveryAddress = {};

                deliveryAddress[this.$store.getters.widgetFields.delivery_city.field_id] =
                    this.$store.getters.checkoutData.delivery.delivery_city;
                deliveryAddress[this.$store.getters.widgetFields.delivery_postal_code.field_id] =
                    this.$store.getters.checkoutData.delivery.delivery_postal_code;
                deliveryAddress[this.$store.getters.widgetFields.delivery_address.field_id] =
                    this.$store.getters.checkoutData.delivery.delivery_address;
                deliveryAddress[this.$store.getters.widgetFields.delivery_co_address.field_id] =
                    this.$store.getters.checkoutData.delivery.delivery_co_address;

                let invoiceAddress = {};

                invoiceAddress[this.$store.getters.widgetFields.invoice_city.field_id] =
                    this.$store.getters.checkoutData.invoice.invoice_city;
                invoiceAddress[this.$store.getters.widgetFields.invoice_postal_code.field_id] =
                    this.$store.getters.checkoutData.invoice.invoice_postal_code;
                invoiceAddress[this.$store.getters.widgetFields.invoice_address.field_id] =
                    this.$store.getters.checkoutData.invoice.invoice_address;
                invoiceAddress[this.$store.getters.widgetFields.invoice_co_address.field_id] =
                    this.$store.getters.checkoutData.invoice.invoice_co_address;

                params['service_id'] = this.$store.getters.selectedServiceId;
                params['supplier_id'] = this.$store.getters.supplierId;
                params['postal_code'] = this.$store.getters.postalCode;
                params['start_date'] = this.$store.getters.selectedGap.start_date;
                params['start_time'] = this.$store.getters.selectedGap.start_time;
                params['end_time'] = this.$store.getters.selectedGap.end_time;
                params['employee_id'] = this.$store.getters.selectedGap.employee_id;
                params['partner_id'] = this.$store.getters.pluginSettings.partner_id;
                params['plugin_id'] = this.$store.getters.pluginSettings.plugin_id;
                params['ongoing_booking_id'] = null;
                params['booking_data'] = bookingData;
                params['internal_comment'] = this.$store.getters.checkoutData.order.order_internal_comment;
                params['delivery_address'] = deliveryAddress;
                params['invoice_address'] = invoiceAddress;
                params['cc'] = this.$store.getters.campaignUuid;

                this.$store.dispatch(FINISH_CHECKOUT, params).then(() => {
                  if (this.$store.getters.finished !== null) {
                    if ('plugin_homepage' in this.$store.getters.pluginSettings
                        && this.$store.getters.pluginSettings.plugin_homepage != null
                        && 'plugin_step_to_return' in this.$store.getters.pluginSettings
                        && this.$store.getters.pluginSettings.plugin_step_to_return == 1) {

                      setTimeout(() => {
                        window.location.href = this.$store.getters.pluginSettings.plugin_homepage
                      }, 300);

                    } else {
                      setTimeout(() => {
                        this.$scrollTo('#headertop');
                        this.clicked = false;

                        this.$store.dispatch(SET_IS_LOADING, false);
                      }, 500);
                    }
                  }
                }); 
            }
          }
        },
        computed: {
          primaryColor() {
            return this.$store.getters.primaryColor;
          }
        },
        data() {
            return {
                clicked: false
            }
        }
    }

</script>