<template>
    <div class="calendar-bokahem-body" v-if="interestFormEnabled"
         v-bind:style="{ color: textColor + ' !important'}">
      <div>

        <div class="row-bokahem" v-if="interestFormIsSent === true">
          <div class="col-bokahem-12">
            <div class="m-auto-bokahem mt-1 text-center-bokahem">
              Tack! Din begäran skickas.
            </div>
          </div>
        </div>

        <div class="row-bokahem" v-if="interestFormIsSent === false">
          <div class="col-bokahem-12" v-if="fetchGapEffort === 0">
            <div class="m-auto-bokahem mt-1 text-center-bokahem" v-if="interestFormIntroductionText">
              {{  interestFormIntroductionText }}
            </div>
          </div>
          <div class="col-bokahem-12 mt-1">
            <div style="height: 14px; font-size: 12px !important; padding-left: 5px;">
              <span v-if="firstname">Förnamn</span>
            </div>
            <div class="info-input-holder-bokahem">
              <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"  type="text" v-model="firstname" placeholder="Förnamn" class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
            </div>
          </div>
          <div class="col-bokahem-12 mt-1">
            <div style="height: 14px; font-size: 12px !important; padding-left: 5px;">
              <span v-if="lastname">Efternamn</span>
            </div>
            <div class="info-input-holder-bokahem">
              <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" type="text" v-model="lastname" placeholder="Efternamn" class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
            </div>
          </div>
          <div class="col-bokahem-12 mt-1">
            <div style="height: 14px; font-size: 12px !important; padding-left: 5px;">
              <span v-if="email">E-post</span>
            </div>
            <div class="info-input-holder-bokahem">
              <input type="email"
                     v-model="email"
                     v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"
                     ref="interest_email"
                     placeholder="E-post"
                     class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">

              <span class="bokahem-valid-error" style="padding-top: 2px; font-size: 14px !important; padding-left: 5px;" v-if="submitted && !$v.email.required">
                E-post krävs
              </span>

            </div>
          </div>
          <div class="col-bokahem-12 mt-1">
            <div style="height: 14px; font-size: 12px !important; padding-left: 5px;">
              <span v-if="phone">Phone</span>
            </div>
            <div class="info-input-holder-bokahem">
              <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" v-model="phone" type="tel" placeholder="Telefon" class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
            </div>
          </div>
          <div class="col-bokahem-12 mt-1">
            <div style="height: 14px; font-size: 12px !important; padding-left: 5px;">
              <span v-if="comment">Önskemål</span>
            </div>
            <div class="info-input-holder-bokahem">
              <textarea v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" v-model="comment" placeholder="Önskemål"  class="textarea-element-bokahem textarea-bokahem border-rounded-bokahem text-black-bokahem font-weight-medium-bokahem" id="importantly"
                        rows="4">
              </textarea>
            </div>
          </div>
          <div class="col-bokahem-12 mt-1">
            <button
                :disabled="disableButton === true"
                style="color: #FFF !important;"
                v-bind:style="{backgroundColor: primaryColor + ' !important'}"
                @mouseover="hover = true"
                @mouseleave="hover = false"
                :class="{ 'btn-hover': hover }"
                @click="setInterestForm()" class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem h-bokahem-100">
              {{ interestFormButtonText }}
            </button>
          </div>
        </div>

      </div>
    </div>

</template>

<script>
import {
  SEND_INTEREST_FORM
} from "@/store/actions.type";
import { required, email } from 'vuelidate/lib/validators';

export default {
  methods: {
    setInterestForm() {

      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let params = {};

      params['first_name'] = this.firstname;
      params['last_name'] = this.lastname;
      params['email'] = this.email;
      params['phone'] = this.phone;
      params['mission_description'] = this.comment;

      params['postal_code'] = this.$store.getters.postalCode;
      params['supplier_id'] = this.$store.getters.supplierId;

      if (this.$store.getters.excludeTag == 1) {
        params['excludeTag'] = 'Ja';
      } else { 
        params['excludeTag'] = 'Nej';
      }
   
      params['recurrence_id'] = this.$store.getters.selectedRecurrenceOptionId;
      params['service_id'] = this.$store.getters.selectedServiceId;
      params['kvm'] = this.$store.getters.kvm;
      params['discount'] = this.$store.getters.newCustomerDiscount;
      
      let serviceTime = '';

      if ('service_time' in this.$store.getters.supplier) {
        serviceTime = this.$store.getters.supplier.service_time;
      }

    //  let bestPrice = '';

     // if ('best_price' in this.$store.getters.supplier) {
     //   bestPrice = this.$store.getters.supplier.best_price;
     // }

      let bestPrice = '';

      if ('best_gaps' in this.$store.getters.gaps) {
        if (this.$store.getters.serviceGroupId == 1) {
           bestPrice = this.$store.getters.gaps.best_gaps[0].widget_prices.pris;
        } else {
           bestPrice = this.$store.getters.gaps.best_gaps[0].widget_prices_company.pris_with_discount;
        }
      } else { 
            
      if ('best_price' in this.$store.getters.supplier) {
        bestPrice = this.$store.getters.supplier.best_price;
      }

      }

       

//      const newCustomerDiscount = '';
  //    if ('new_customer_discount' in this.$store.getters.supplier) {
    //    newCustomerDiscount = this.$store.getters.supplier.new_customer_discount;
    //  }

      if ('id' in this.$store.getters.pluginSettings) {
        params['plugin_id'] = this.$store.getters.pluginSettings.id;
      }

      const rabbat_display = 'display:none;';
	
      params['short_summary'] = this.$store.getters.selectedServiceName
            + ' ' + this.$store.getters.selectedRecurrenceOption + ', '
            + this.$store.getters.postalCode + ', '
            + this.$store.getters.kvm + ' kvm, '
            + serviceTime + ' tim, ' + bestPrice + ' kr/tillfälle.';

      let gapCalendarStart = '';
      let gapCalendarEnd = '';

      if ('formatted_days' in this.$store.getters.gaps) {
        if ('start_week' in this.$store.getters.gaps.formatted_days) {
          gapCalendarStart = this.$store.getters.gaps.formatted_days.start_week;
        }

        if ('end_week' in this.$store.getters.gaps.formatted_days) {
          gapCalendarEnd = this.$store.getters.gaps.formatted_days.end_week;
        }
      }

      params['gap_calendar_start'] = gapCalendarStart;
      params['gap_calendar_end'] = gapCalendarEnd;

      if ('gap_info' in this.$store.getters.gaps) {
        params['gaps_not_found_reason'] =  this.$store.getters.gaps.gap_info;
      }

      if ('calendar_gaps' in this.$store.getters.gaps) {
        params['is_gaps'] = true;
      } else {
        params['is_gaps'] = false;
      }


      let additional_info = {};
     
      additional_info['postalCode'] = this.$store.getters.postalCode;
      additional_info['service'] = this.$store.getters.selectedServiceName;
      additional_info['recurrence'] = this.$store.getters.selectedRecurrenceOption;
      additional_info['kvm'] = this.$store.getters.kvm;

      if (this.$store.getters.excludeTag == 1) {
        additional_info['excludeTag'] = 'Ja';
      } else { 
        additional_info['excludeTag'] = 'Nej';
      }

      additional_info['price'] = bestPrice + ' kr/tillfälle';
      additional_info['service_time'] =  serviceTime + ' tim';
//      additional_info['discount'] = 'Ny kund ' + newCustomerDiscount + '%, gäller vid första tillfället';
      additional_info['rabbat_display'] = rabbat_display;
      additional_info['date'] = gapCalendarStart + '-' + gapCalendarEnd;
      additional_info['first_name'] = this.firstname;
      additional_info['last_name'] = this.lastname;
      additional_info['mission_description'] = this.comment;
      additional_info['phone'] = this.phone;
      additional_info['is_gaps'] = params['is_gaps'] === true ? 'Ja' : 'Nej';
      additional_info['email'] = this.email;
      additional_info['supplier'] = this.$store.getters.supplier.name;
      additional_info['supplier_email'] = this.$store.getters.supplier.name;
      additional_info['supplier_phone'] = this.$store.getters.supplier.contact_information.phone;
      additional_info['supplier_email'] = this.$store.getters.supplier.contact_information.email;

      params['email_data'] = additional_info;

      this.disableButton = true;

      this.$store.dispatch(SEND_INTEREST_FORM, params);
    }
  },
  computed: {
    backgroundPrimaryColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
    interestFormButtonText: {
      get() {
        if ('interest_form_button_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.interest_form_button_text;
        } else {
          return 'Skicka';
        }
      }
    },
    interestFormIntroductionText: {
      get() {
        if ('interest_form_introduction_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.interest_form_introduction_text;
        } else {
          return null;
        }
      }
    },
    interestFormEnabled: {
      get () {
        if ('show_price' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.show_price;
        } else {
          return false;
        }
      }
    },
    fetchGapEffort: {
      get() {
        return this.$store.getters.fetchGapEffort;
      }
    },
    interestFormIsSent: {
      get() {
        return this.$store.getters.interestFormIsSent;
      }
    },
    primaryColor: {
      get() {
        return this.$store.getters.primaryColor;
      }
    },
    textColor: {
      get() {
        return this.$store.getters.textColor;
      }
    },
  },
  data() {
    return {
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      comment: null,
      submitted: false,
      hover: false,
      disableButton: false
    }
  },
  validations: {
    email: {
      email, required
    }
  }
}
</script>
