<template>
  <h2 class="bokahem-h2" v-if="headerTitle" v-bind:style="{ color: fontColor + ' !important'}">
    {{ headerTitle }}
  </h2>
</template>

<script>
export default {
  computed: {
    headerTitle: {
      get() {
        if ('header_title' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.header_title;
        } else {
          return null;
        }
      }
    },
    fontColor: {
      get() {
        return this.$store.getters.primaryMediumColor;
      }
    }
  }
}
</script>