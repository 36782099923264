<template>
  <div id="widget" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">
    <div v-if="finished === null">
      <main v-show="pluginSettings && approvedCheckout == false" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">
        <div class="col-bokahem-12 m-auto-bokahem company-cleaning-card-bokahem_width mt-1 mb-1 text-center-bokahem">
          <HeadingTitle></HeadingTitle>
        </div>
      </main>

      <main v-if="approvedCheckout == false && isBookingPlugin === 1 && isLoading === false" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">
        <PostalCodeInformation></PostalCodeInformation>
      </main>

      <header v-show="pluginSettings && approvedCheckout == false" 
      class="header-bokahem" 
      v-bind:style="{ color: textColor + ' !important', backgroundColor: backgroundMainColor + ' !important'}">
        <div class="container-bokahem" id="headertop"  v-cloak--hidden>
          <div class="row-bokahem no-gutters justify-content-center-bokahem">

            <div class="col-bokahem-12 col-bokahem-xs-5 col-bokahem-md-9 col-bokahem-mdl-9 col-bokahem-xl-6"
                 style="padding-right:2px;">
              <div class="row-bokahem no-gutters">
                <PostalCodeInput></PostalCodeInput>
                <ServiceInput></ServiceInput>
                <ParameterInput></ParameterInput>
              </div>
            </div>

            <div class="col-bokahem-12 col-bokahem-xs-5 col-bokahem-md-9 col-bokahem-mdl-9 col-bokahem-xl-6">
              <div class="row-bokahem no-gutters">
                <RecurrenceInput></RecurrenceInput>
                <ExcludeTagInput></ExcludeTagInput>
              </div>
            </div>
          </div>

          <div class="col-bokahem-12 m-auto" style="width:400px;margin:auto;">
            <div class="row-bokahem no-gutters justify-content-center-bokahem mt-1">
              <div v-show="additionalParameter === true" class="col-bokahem-12">
                <div class="row-bokahem no-gutters">
                  <div class="col-bokahem-12 mr-1">
                    <AdditionalParameter></AdditionalParameter>
                  </div>
                </div>
              </div>

              <div v-show="serviceGroupId === 2" class="col-bokahem-12">
                <div class="row-bokahem no-gutters">
                  <div class="col-bokahem-12 mr-1">
                    <BathroomParameter></BathroomParameter>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row-bokahem no-gutters justify-content-center-bokahem">
            <div class="col-bokahem-12 col-bokahem-xs-6 col-bokahem-md-9 col-bokahem-mdl-9 col-bokahem-xl-6">
              <div class="row-bokahem no-gutters">
                <div class="col-bokahem-12">
                  <ServiceDescription v-if="isBookingPlugin == 1"></ServiceDescription>
                  <SearchButton v-if="isBookingPlugin == 0"></SearchButton>
                  <GapsButton v-if="isBookingPlugin == 1"></GapsButton>
                  <div style="font-size:14.22px" class="text-center-bokahem mt-1 bokahem-power-by"
                       v-show="formattedDays == null && isBookingPlugin === 1">
                    I samarbete med <a href="https://boka.bokahem.se" class="bokahem-power-by" target="_blank">Bokahem</a>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main v-if="approvedCheckout == false" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">

        <div class="container-bokahem" v-show="isLoading === true" >
          <div class="row-bokahem no-gutters justify-content-center-bokahem">
        <div class="col-bokahem-12 m-auto
             company-cleaning-card-bokahem_width mt-1 mb-1 text-center" style="font-style:italic;z-index:0 !important;">
           <span class="pr-3 text-black-bokahem" style="z-index:0 !important;">
              <vue-element-loading delay="1" :color="primaryMediumColor" :backgroundColor="backgroundMainColor" size="30" spinner="spinner" :active="isLoading" />
           </span>
          <span class="text-black-bokahem m-auto-bokahem text-center-bokahem">
           Vi letar lediga tider i ditt område beräknar restider och priser.
          </span>
        </div>
          </div>
        </div>

        <div v-bind:style="{'color': textColor + ' !important'}" id="gapcalendar" v-if="headerActive === false">
          <GapCalendar></GapCalendar>
          <InterestFormWrap></InterestFormWrap>
          <PostalCodeInformation v-if="isBookingPlugin === 0"></PostalCodeInformation>
          <hr>
          <div class="container-bokahem" style="display: none;">
            <div class="row-bokahem no-gutters justify-content-center-bokahem">
              <div class="col-bokahem-12 col-bokahem-md-5 col-bokahem-xl-6 position-end check_border_bokahem">
              </div>
            </div>
          </div>

          <div class="container-bokahem">
            <div class="row-bokahem no-gutters justify-content-center-bokahem" id="gapsummary">
              <GapSummary v-if="selectedGap !== null && isLoading === false"></GapSummary>
            </div>
          </div>

          <div class="container-bokahem">
            <div class="row-bokahem no-gutters justify-content-center-bokahem">
              <CheckoutForm v-if="serviceGroupId == 1 && selectedGap !== null && isLoading === false"></CheckoutForm>
              <CheckoutFormCompany v-if="serviceGroupId == 2 && selectedGap !== null && isLoading === false"></CheckoutFormCompany>
           </div>
          </div>
        </div>
      </main>

      <main v-if="approvedCheckout == true" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">
      <div class="container-bokahem">
        <div class="row-bokahem no-gutters justify-content-center-bokahem">
          <Checkout v-if="checkoutData !== null && finished === null"></Checkout>
        </div>
      </div>
      </main>
    </div>

    <main v-if="finished !== null" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">
      <div class="container-bokahem">
        <div class="row-bokahem no-gutters justify-content-center-bokahem">
          <Finish v-if="finished !== null"></Finish>
        </div>
      </div>
    </main>

      <div class="row-bokahem no-gutters justify-content-center-bokahem" v-bind:style="{ backgroundColor: backgroundMainColor + ' !important'}">
        <div class="col-bokahem-12 col-bokahem-xs-6 col-bokahem-md-9 col-bokahem-mdl-9 col-bokahem-xl-6">
              <div class="text-center-bokahem bokahem-power-by" v-show="formattedDays !== null && isBookingPlugin == 1">
                I samarbete med <a href="https://boka.bokahem.se" class="bokahem-power-by" target="_blank">Bokahem</a>.
              </div>
        </div>
      </div>

    <modals-container/>

  </div>
</template>
<script>

import PostalCodeInformation from "@/components/PostalCodeInformation";
import PostalCodeInput from "@/components/PostalCodeInput";
import ServiceInput from "@/components/ServiceInput";
import RecurrenceInput from "@/components/RecurrenceInput";
import ExcludeTagInput from "@/components/ExcludeTagInput";
import ParameterInput from "@/components/ParameterInput";
import InterestFormWrap from "@/components/InterestFormWrap";
import CheckoutForm from "@/components/CheckoutForm";
import CheckoutFormCompany from "@/components/CheckoutFormCompany";
import ServiceDescription from "@/components/ServiceDescription";

import SearchButton from "@/components/SearchButton";

import GapsButton from "@/components/GapsButton";
import Finish from "@/components/Finish";

import AdditionalParameter from "@/components/AdditionalParameter";
import BathroomParameter from "@/components/BathroomParameter";

import './assets/scss/style.scss';
import './assets/custom-svg/sprites.svg';

import {FETCH_PLUGIN_SETTINGS, FETCH_SUPPLIER_SERVICES, FETCH_GAPS} from "@/store/actions.type";
import {SET_POSTAL_CODE, SET_KVM, SET_HEADER_ACTIVE} from "@/store/mutations.type";
import HeadingTitle from "@/components/HeadingTitle";
import GapCalendar from "@/components/GapCalendar";
import GapSummary from "@/components/GapSummary";
import Checkout from "@/components/Checkout";
import VueElementLoading from "vue-element-loading";

import classHelper from './core/helpers/classes';

export default {
  name: 'App',
  props: ['service', 'postnr', 'kvm'],
  components: {
    GapSummary,
    ServiceDescription,
    GapCalendar,
    HeadingTitle,
    SearchButton,
    InterestFormWrap,
    ParameterInput,
    ExcludeTagInput,
    RecurrenceInput,
    ServiceInput,
    PostalCodeInput,
    PostalCodeInformation,
    AdditionalParameter,
    GapsButton,
    CheckoutForm,
    CheckoutFormCompany,
    Checkout,
    Finish,
    VueElementLoading,
    BathroomParameter
  },
  data() {
    return {
      pluginSettings: false,
      label: 'Vi letar lediga tider i ditt område,\n' +
          'beräknar restider och priser.',
      loaderClass: 'loader-wrapper-bokahem'
    }
  },
  mounted() {
    this.$store.dispatch(FETCH_PLUGIN_SETTINGS);

    window.addEventListener('load', () => {
        classHelper.fitPluginForParentBlock();
    })
    window.addEventListener('resize', () => {
        classHelper.fitPluginForParentBlock();
    })
    
  },
  updated: function () {
    this.$nextTick(function () {
      setTimeout(() => {
        this.pluginSettings = true;
      }, 800);
    });
  },
  watch: {
    '$store.state.gaps': function() {
      
      this.label = 'Vi letar lediga tider i ditt område,\n' +
          'beräknar restider och priser.';

      if (this.$store.state.noEffort == true) {
        return;
      }

      let nextWeek = false;

      if (this.$store.state.fetchGapEffort > 2) {
        return;
      }

      if ('best_gaps' in this.$store.state.gaps) {
        if ('0' in this.$store.state.gaps.best_gaps) {
          nextWeek = false;
        } else {
          nextWeek = true;
        }
      } else {
        nextWeek = true;
      }

      if (nextWeek) {
        if ('formatted_days' in this.$store.state.gaps) {
          if ('next_week_start_date' in this.$store.state.gaps.formatted_days
              && 'next_week_end_date' in this.$store.state.gaps.formatted_days) {

              let params = {};
              let serviceParameter = {};

              if (this.$store.state.serviceGroupId == 2) {
                serviceParameter['bathroom'] = this.$store.state.bathroom;
                if (this.$store.state.kitchen === true) {
                  serviceParameter['kitchen'] = 1;
                } else {
                  serviceParameter['kitchen'] = 0;
                }
              }

              if (this.$store.getters.additionalParameter == true) {

                if (this.$store.state.bars === true) {
                  serviceParameter['bars'] = 1;
                } else {
                  serviceParameter['bars'] = 0;
                }

                if (this.$store.state.frames === true) {
                  serviceParameter['frames'] = 1;
                } else {
                  serviceParameter['frames'] = 0;
                }

                if (this.$store.state.balcony === true) {
                  serviceParameter['balcony'] = 1;
                } else {
                  serviceParameter['balcony'] = 0;
                }

                if (this.$store.state.ladder === true) {
                  serviceParameter['ladder'] = 1;
                } else {
                  serviceParameter['ladder'] = 0;
                }

                if (this.$store.state.fourSides === true) {
                  serviceParameter['4sides'] = 1;
                } else {
                  serviceParameter['4sides'] = 0;
                }

                serviceParameter['extra_windows'] = this.$store.state.extraWindows;

                if (this.$store.state.extraWindows > 0) {
                  serviceParameter['is_extra_windows'] = 1;
                } else {
                  serviceParameter['is_extra_windows'] = 0;
                }
              }

              serviceParameter['square_meters'] = this.$store.state.kvm;

              params['start_date'] = this.$store.state.gaps.formatted_days.next_week_start_date;
              params['end_date'] = this.$store.state.gaps.formatted_days.next_week_end_date;
              params['service_id'] = this.$store.state.selectedServiceId;
              params['postal_code'] = this.$store.state.postalCode;
              params['service_parameters'] = serviceParameter;
              params['service_category_id'] = this.$store.state.serviceCategoryId;
              params['service_group_id'] = this.$store.state.serviceGroupId;
              params['supplier_id'] = this.$store.state.supplierId;
              params['cleanEffort'] = false;
              params['starting_date'] = new Date();

              let selectedEmployees = this.$store.state.selectedEmployees;
                    let employeeIds = selectedEmployees.join(',');
              
              params['employee_ids'] = employeeIds;

              this.$store.dispatch(FETCH_GAPS, params).then(() => {
                this.$scrollTo('#gapcalendar');
                this.$store.dispatch(SET_HEADER_ACTIVE, false);
              });
          }
        }
       
      }
      
    },
    '$store.state.supplierId': function() {

      let params = {};

      let postalCode = null;

      // Get dynamic parameters
      let query = this.$router.history.current.query;

      if ('postnr' in query) {
        postalCode = query.postnr;
      } else if ('postnr' in this.$root && this.$root.postnr != null) {
        postalCode = this.$root.postnr;
      } else if ('postal_code' in this.$store.getters.pluginSettings) {
        postalCode = this.$store.getters.pluginSettings.postal_code;
      }

      params['postal_code'] = postalCode;
      params['supplier_id'] = this.$store.state.supplierId;

      let kvm = null;

      if ('kvm' in query) {
        kvm = query.kvm;
      } else if ('kvm' in this.$root && this.$root.kvm != null) {
        kvm = this.$root.kvm;
      } else if ('default_kvm' in this.$store.getters.pluginSettings) {
        kvm = this.$store.getters.pluginSettings.default_kvm;
      }

      this.$store.dispatch(SET_POSTAL_CODE, postalCode);
      this.$store.dispatch(SET_KVM, kvm);

       if (postalCode !== null) {
        this.$store.dispatch(FETCH_SUPPLIER_SERVICES, params);
      }
    },
    '$store.state.eveningDay': function() {
      let params = {};

      let serviceParameter = {};

      if (this.$store.state.serviceGroupId == 2) {
          serviceParameter['bathroom'] = this.$store.state.bathroom;
          if (this.$store.state.kitchen === true) {
            serviceParameter['kitchen'] = 1;
          } else {
            serviceParameter['kitchen'] = 0;
          }
      }

      if (this.$store.state.additionalParameter == true) {

        if (this.$store.state.bars === true) {
          serviceParameter['bars'] = 1;
        } else {
          serviceParameter['bars'] = 0;
        }

        if (this.$store.state.frames === true) {
          serviceParameter['frames'] = 1;
        } else {
          serviceParameter['frames'] = 0;
        }

        if (this.$store.state.balcony === true) {
          serviceParameter['balcony'] = 1;
        } else {
          serviceParameter['balcony'] = 0;
        }

        if (this.$store.state.ladder === true) {
          serviceParameter['ladder'] = 1;
        } else {
          serviceParameter['ladder'] = 0;
        }

        if (this.$store.state.fourSides === true) {
          serviceParameter['4sides'] = 1;
        } else {
          serviceParameter['4sides'] = 0;
        }

        serviceParameter['extra_windows'] = this.$store.state.extraWindows;

        if (this.$store.state.extraWindows > 0) {
          serviceParameter['is_extra_windows'] = 1;
        } else {
          serviceParameter['is_extra_windows'] = 0;
        }
      }

      serviceParameter['square_meters'] = this.$store.state.kvm;

      params['service_id'] = this.$store.state.selectedServiceId;
      params['postal_code'] = this.$store.state.postalCode;
      params['service_parameters'] = serviceParameter;
      params['service_category_id'] = this.$store.state.serviceCategoryId;
      params['service_group_id'] = this.$store.state.serviceGroupId;

      params['supplier_id'] = this.$store.state.supplierId;
      params['cleanEffort'] = true;
      params['showSupplier'] = true;
      params['check_supplier'] = true;
      params['starting_date'] = this.$store.state.startingDate;
      params['time_filter'] = this.$store.state.eveningDay;

      this.$store.dispatch(FETCH_GAPS, params);
    },
    '$store.state.startingDate': function() {
      
           
      let params = {};

      let serviceParameter = {};

      if (this.$store.state.serviceGroupId == 2) {
          serviceParameter['bathroom'] = this.$store.state.bathroom;
          if (this.$store.state.kitchen === true) {
            serviceParameter['kitchen'] = 1;
          } else {
            serviceParameter['kitchen'] = 0;
          }
        }

      if (this.$store.state.additionalParameter == true) {

        if (this.$store.state.bars === true) {
          serviceParameter['bars'] = 1;
        } else {
          serviceParameter['bars'] = 0;
        }

        if (this.$store.state.frames === true) {
          serviceParameter['frames'] = 1;
        } else {
          serviceParameter['frames'] = 0;
        }

        if (this.$store.state.balcony === true) {
          serviceParameter['balcony'] = 1;
        } else {
          serviceParameter['balcony'] = 0;
        }

        if (this.$store.state.ladder === true) {
          serviceParameter['ladder'] = 1;
        } else {
          serviceParameter['ladder'] = 0;
        }

        if (this.$store.state.fourSides === true) {
          serviceParameter['4sides'] = 1;
        } else {
          serviceParameter['4sides'] = 0;
        }

        serviceParameter['extra_windows'] = this.$store.state.extraWindows;

        if (this.$store.state.extraWindows > 0) {
          serviceParameter['is_extra_windows'] = 1;
        } else {
          serviceParameter['is_extra_windows'] = 0;
        }
      }

      serviceParameter['square_meters'] = this.$store.state.kvm;

      params['service_id'] = this.$store.state.selectedServiceId;
      params['postal_code'] = this.$store.state.postalCode;
      params['service_parameters'] = serviceParameter;
      params['service_category_id'] = this.$store.state.serviceCategoryId;
      params['service_group_id'] = this.$store.state.serviceGroupId;

      params['supplier_id'] = this.$store.state.supplierId;
      params['cleanEffort'] = true;
      params['showSupplier'] = true;
      params['check_supplier'] = true;
      params['starting_date'] = this.$store.state.startingDate;
      params['time_filter'] = this.$store.state.eveningDay;

//      this.$store.dispatch(FETCH_GAPS, params);
      
    },
  },
  computed: {
    backgroundColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
    backgroundMainColor: {
      get () {
        return this.$store.getters.backgroundMainColor;
      }
    },
    primaryColor: {
      get () {
        return this.$store.getters.primaryColor;
      }
    },
    formattedDays: {
      get() {
        if ('formatted_days' in this.$store.getters.gaps) {
          if ('previous_week_start_date' in this.$store.getters.gaps.formatted_days
              && 'previous_week_end_date' in this.$store.getters.gaps.formatted_days
              && 'next_week_start_date' in this.$store.getters.gaps.formatted_days
              && 'next_week_end_date' in this.$store.getters.gaps.formatted_days) {
            return this.$store.getters.gaps.formatted_days;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    },
    textColor: {
      get () {
        return this.$store.getters.textColor;
      }
    },
    selectedGap: {
      get() {
        return this.$store.getters.selectedGap;
      }
    },
    checkoutData: {
      get() {
        return this.$store.getters.checkoutData;
      }
    },
    headerActive: {
      get() {
        return this.$store.getters.headerActive;
      }
    },
    isBookingPlugin: {
      get() {
        return this.$store.getters.isBookingPlugin;
      }
    },
    isInternalPlugin: {
      get() {
        return this.$store.getters.isInternalPlugin;
      }
    },
    additionalParameter: {
      get() {
        return this.$store.getters.additionalParameter;
      }
    },
    serviceGroupId: {
      get() {
        return this.$store.getters.serviceGroupId;
      }
    },
    gaps: {
      get() {
        return this.$store.getters.gaps;
      }
    },
    isLoading: {
      get () {
        return this.$store.getters.isLoading;
      }
    },
    approvedCheckout: {
      get () {
        return this.$store.getters.approvedCheckout;
      }
    },
    finished: {
      get () {
        return this.$store.getters.finished;
      }
    }
  }
}
</script>
