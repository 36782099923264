<template>
  <div @click="showExcludeTagSelect()" style="min-width: 120px;" class="col-bokahem-4 col-bokahem-sm-2 col-bokahem-md-3
  col-bokahem-xl-2 input-wrap-col-bokahem">
    <div class="header-bokahem-block-label">
      {{ animalsSubtitle }}
    </div>
    <div v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" :class="{'select_active': excludeTagSelectShow }" id="tag-select" class="header-bokahem-block header-bokahem-block-margin select select-js border-rounded-bokahem--right select_sm">
      <div v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" class="select-heading-bokahem">
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" unselectable="on" class="select-current">{{ selectedExcludeTag }}</span>
      </div>
      <div v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" class="select-body select-body-js">
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" @click="selectExcludeTag(0)" class="select-option-bokahem">{{ animalsNoOption }}</span>
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" @click="selectExcludeTag(1)" class="select-option-bokahem service-selected-option">{{ animalsYesOption }}</span>
      </div>
      <svg class="icon-svg-bokahem">
        <DogIcon></DogIcon>
      </svg>
    </div>
  </div>
</template>

<script>
import {SET_EXCLUDE_TAG, SET_HEADER_ACTIVE} from "@/store/mutations.type";
import DogIcon from '@/assets/custom-svg/icons/dog.svg';

export default {
  components: {
    DogIcon
  },
  watch: {
    '$store.state.pluginSettings': function() {
      if ('animals_no_option_text' in this.$store.state.pluginSettings) {
        this.selectedExcludeTag = this.$store.state.pluginSettings.animals_no_option_text;
      }
    }
  },
  data() {
    return {
      selectedExcludeTag: null,
      excludeTagSelectShow: false
    }
  },
  methods: {
    selectExcludeTag(excludeTagKey) {
      if (excludeTagKey == 0) {
        this.selectedExcludeTag = this.animalsNoOption;
      } else if (excludeTagKey == 1) {
        this.selectedExcludeTag = this.animalsYesOption;
      } else {
        this.selectedExcludeTag = this.animalsNoOption;
      }
      this.$store.dispatch(SET_HEADER_ACTIVE, true);
      this.$store.dispatch(SET_EXCLUDE_TAG, excludeTagKey);
    },
    showExcludeTagSelect() {
      this.excludeTagSelectShow = this.excludeTagSelectShow === true ? false : true;
    }
  },
  computed: {
    animalsSubtitle: {
      get() {
        if ('animals_subtitle' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.animals_subtitle;
        } else {
          return null;
        }
      }
    },
    animalsYesOption: {
      get() {
        if ('animals_yes_option_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.animals_yes_option_text;
        } else {
          return 'Ja';
        }
      }
    },
    animalsNoOption: {
      get() {
        if ('animals_no_option_text' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.animals_no_option_text;
        } else {
          return 'Nej';
        }
      }
    },
    backgroundPrimaryColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
  },
}
</script>