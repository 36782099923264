<template>
  <div class="flex-grow-bokahem-1" style="flex-grow: 1 !important;" @click="showRecurrenceOptionSelect()">
    <div class="header-bokahem-block-label">
      <span>{{ recurrenceSubtitle }}</span>
    </div>
    <div v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" :class="{'select_active': recurrenceOptionSelectShow }" id="recurrence-select-2" class="header-bokahem-block header-bokahem-block-margin select border-rounded-bokahem--left border-right--none select-js">
      <div class="select-heading-bokahem" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}">
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" style="max-width:100% !important;" id="select-recurrence-bokahem-wrapping" unselectable="on" class="select-current">
          {{ selectedRecurrenceOption }}
        </span>

        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" @click="clickOnService()" v-if="supplierServices.length == 0" unselectable="on" class="select-current" style="color: #a4b2bb !important;">
          {{ recurrencePlaceholder }}
        </span>

      </div>

      <div v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" class="select-body select-body-js">
        <span v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" class="select-option-bokahem service-select-current"
              @click="selectRecurrenceOption(recurrenceOptionKey, recurrenceOption.name)" v-bind:key="recurrenceOptionKey"
              v-for="(recurrenceOption,recurrenceOptionKey) in recurrenceOptions">
           {{ recurrenceOption.name }}
         </span>
      </div>
      <svg class="icon-svg-bokahem">
        <CalendarIcon></CalendarIcon>
      </svg>
    </div>
  </div>

</template>

<script>
import {
  SELECT_RECURRENCE_OPTION
} from "@/store/actions.type";

import CalendarIcon from '@/assets/custom-svg/icons/calendar.svg';
import {SET_HEADER_ACTIVE, SET_SERVICE_CLICKED} from "@/store/mutations.type";

export default {
  components: {
    CalendarIcon
  },
  computed: {
    backgroundPrimaryColor: {
      get () {
        return this.$store.getters.backgroundPrimaryColor;
      }
    },
    recurrenceSubtitle: {
      get() {
        if ('recurrence_subtitle' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.recurrence_subtitle;
        } else {
          return null;
        }
      }
    },
    recurrencePlaceholder: {
      get() {
        if ('recurrence_placeholder' in this.$store.getters.pluginSettings) {
          return this.$store.getters.pluginSettings.recurrence_placeholder;
        } else {
          return null;
        }
      }
    },
    supplierServices: {
      get() {
        return this.$store.getters.supplierServices;
      }
    }
  },
  data() {
    return {
      recurrenceOptions: [],
      selectedRecurrenceOption: null,
      recurrenceOptionSelectShow: false
    }
  },
  watch: {
    '$store.state.recurrenceOptions': function() {
      this.recurrenceOptions = this.$store.state.recurrenceOptions;

     // if (this.selectedRecurrenceOption === null) {
        if ('0' in this.$store.state.recurrenceOptions) {
          this.selectedRecurrenceOption = this.$store.state.recurrenceOptions[0].name;
          this.$store.dispatch(SELECT_RECURRENCE_OPTION, 0);
        }
    //  }
    },
    '$store.state.selectedRecurrenceOption': function() {
      if (this.$store.state.selectedRecurrenceOption === null) {
        this.selectedRecurrenceOption = null;
      }
      if (this.$store.state.supplierServices.length == 0) {
        this.selectedRecurrenceOption = null;
      }
    },
  },
  methods: {
    selectRecurrenceOption(recurrenceOptionKey, recurrenceOptionName) {
      this.selectedRecurrenceOption = recurrenceOptionName;
      this.$store.dispatch(SELECT_RECURRENCE_OPTION, recurrenceOptionKey);
      this.$store.dispatch(SET_HEADER_ACTIVE, true);
    },
    showRecurrenceOptionSelect() {
      this.recurrenceOptionSelectShow = this.recurrenceOptionSelectShow === true ? false : true;
    },
    clickOnService() {
      this.$store.dispatch(SET_SERVICE_CLICKED, true);
    },
  }
}
</script>