<template>
    <div class="col-bokahem-12 bokahem-central-position" v-bind:style="{ color: textColor + ' !important'}">
        <div class="row-bokahem" v-if="widgetFields !== null">
            <div class="col-bokahem-12">
                <h2 class="bokahem-h2 widget-subtitle mb-0 mt-0
                    bokahem-best-gap-title
                    widget-subtitle_sm
                    font-weight-semi-bokahem
                    text-primary
                    letter-spacing_small" v-bind:style="{ color: primaryMediumColor + ' !important'}" id="checkoutform">
                  Fyll i din information
                </h2>

                <div class="row-bokahem">

                  <div class="col-bokahem-6 address-block-desktop">

                    <div class="bokahem-field-name-block" style="height: auto !important;">
                              <span>
                                {{ widgetFields.order_personal_number.name }} *
                              </span>
                    </div>

                    <div class="info-input-holder">
                      <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="order_personal_number" type="text" v-model="order.order_personal_number"
                             :placeholder="widgetFields.order_personal_number.placeholder"
                             @keyup="customPersonalNumberValidate"
                             class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                      <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_personal_number.required">
                                {{ widgetFields.order_personal_number.name }} krävs
                            </span>

                      <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="(submitted || searchPersonalClick) && customValidations.order_personal_number === false">
                                {{ widgetFields.order_personal_number.name }} är inte giltig
                            </span>
                    </div>
                  </div>

                  <div class="col-bokahem-6 address-block-desktop">

                    <div class="bokahem-field-name-block" style="height: auto !important;">
                      <span>
                        Din adress hämtas från folkbokföringen
                      </span>
                    </div>

                    <button
                        v-bind:style="{ backgroundColor: primaryColor + ' !important' }"
                        @click="searchPersonalNumber()"
                        style="height: 44px !important;"
                        class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem"
                        :disabled="isLoadingPn">
                      <span v-if="!isLoadingPn">Hämta adress</span>
                      <span v-else class="loaderPn"></span>
                    </button>
                  </div>

                  <div class="col-bokahem-12 address-block-mobile">

                    <div class="bokahem-field-name-block" style="height: auto !important;">
                              <span>
                                {{ widgetFields.order_personal_number.name }} *
                              </span>
                    </div>

                    <div class="info-input-holder">
                      <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="order_personal_number" type="text" v-model="order.order_personal_number"
                            :placeholder="widgetFields.order_personal_number.placeholder"
                            @keyup="customPersonalNumberValidate"
                            class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                      <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_personal_number.required">
                                {{ widgetFields.order_personal_number.name }} krävs
                            </span>

                      <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="(submitted || searchPersonalClick) && customValidations.order_personal_number === false">
                                {{ widgetFields.order_personal_number.name }} är inte giltig
                            </span>

                      <span class="bokahem-valid-error" style="color: #337ab7 !important;"
                            v-show="pnNoResult === true">
                            Kontakten med Folkbokföringen fungerar inte just nu. Var vänlig skriv din information manuellt.
                      </span>
                    </div>
                    </div>

                    <div class="col-bokahem-12 address-block-mobile">

                    <div class="bokahem-field-name-block" style="height: auto !important;">
                      <span>
                        Din adress hämtas från folkbokföringen
                      </span>
                    </div>
                      
                      <button
                          v-bind:style="{ backgroundColor: primaryColor + ' !important' }"
                          @click="searchPersonalNumber()"
                          style="height: 44px !important;"
                          class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem"
                          :disabled="isLoadingPn"
                      >
                        <span v-if="!isLoadingPn">Hämta adress</span>
                        <span v-else class="loaderPn"></span>
                      </button>

                    </div>

                    <div class="col-bokahem-12">
                      <div class="bokahem-field-name-block">
                        <span>
                          {{ widgetFields.order_first_name.name }} *
                        </span>
                      </div>

                        <div class="info-input-holder" id="bokahem_first_name">
                            <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="order_first_name" type="text" v-model="order.order_first_name" :placeholder="widgetFields.order_first_name.placeholder"
                                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_first_name.required">
                                {{ widgetFields.order_first_name.name }} krävs
                            </span>
                        </div>
                    </div>

                    <div class="col-bokahem-12">

                        <div class="bokahem-field-name-block">
                          <span>
                            {{ widgetFields.order_last_name.name }} *
                          </span>
                        </div>

                        <div class="info-input-holder" id="bokahem_last_name">
                            <input ref="order_last_name" type="text"
                                   v-model="order.order_last_name" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"
                                   :placeholder="widgetFields.order_last_name.placeholder"
                                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_last_name.required">
                                 {{ widgetFields.order_last_name.name }} krävs
                            </span>
                        </div>
                    </div>

                    <div class="col-bokahem-12">
                        <div class="bokahem-field-name-block">
                          <span>
                            {{ widgetFields.delivery_address.name }} *
                          </span>
                        </div>
                        <div class="info-input-holder" id="bokahem_delivery_address">
                            <input ref="delivery_address" type="text"
                                   v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"
                                   v-model="deliveryAddress.delivery_address"
                                   :placeholder="widgetFields.delivery_address.placeholder"
                                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                            <span class="bokahem-valid-error"
                                  v-bind:style="{ color: validationColor + ' !important'}"
                                  v-if="submitted && !$v.deliveryAddress.delivery_address.required">
                                {{ widgetFields.delivery_address.name }} krävs
                            </span>
                        </div>
                    </div>

                    <div class="col-bokahem-12">
                      <div class="bokahem-field-name-block">
                          <span>
                            {{ widgetFields.delivery_co_address.name }}
                          </span>
                      </div>
                        <div class="info-input-holder">
                            <input type="text" v-model="deliveryAddress.delivery_co_address"
                                   v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"
                                   :placeholder="widgetFields.delivery_co_address.placeholder"
                                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                        </div>
                    </div>

                    <div class="col-bokahem-12">
                        <div class="row-bokahem no-gutters">
                            <div class="col-bokahem-4">
                                <div class="bokahem-field-name-block">
                                        <span>
                                          {{ widgetFields.delivery_postal_code.name }} *
                                        </span>
                                </div>
                                <div class="info-input-holder" id="bokahem_delivery_postal_code">
                                    <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="delivery_postal_code" type="text" v-model="deliveryAddress.delivery_postal_code" name="zip"
                                           :placeholder="widgetFields.delivery_postal_code.placeholder"
                                           class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem--left font-weight-medium-bokahem">
                                    <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                    && !$v.deliveryAddress.delivery_postal_code.required">
                                        {{ widgetFields.delivery_postal_code.name }} krävs
                                    </span>
                                </div>
                            </div>
                            <div class="col-bokahem-8">
                              <div class="bokahem-field-name-block">
                                        <span>
                                          {{ widgetFields.delivery_city.name }} *
                                        </span>
                              </div>
                                <div class="info-input-holder" id="bokahem_delivery_city">
                                    <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="delivery_city" type="text" v-model="deliveryAddress.delivery_city"
                                           :placeholder="widgetFields.delivery_city.placeholder"
                                           class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem--right border-left--none font-weight-medium-bokahem">
                                    <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                    && !$v.deliveryAddress.delivery_city.required">
                                        {{ widgetFields.delivery_city.name }} krävs
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-bokahem-12">
                        <div class="bokahem-field-name-block">
                            <span>
                              {{ widgetFields.order_email.name }} *
                            </span>
                        </div>

                        <div class="info-input-holder" id="bokahem_email">
                            <input ref="order_email" type="email" v-model="order.order_email"
                                   :placeholder="widgetFields.order_email.placeholder" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"
                                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">

                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_email.required">
                                 {{ widgetFields.order_email.name }} krävs
                            </span>

                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_email.email">
                                {{ widgetFields.order_email.name }} är inte giltig
                            </span>
                        </div>
                    </div>
                    <div class="col-bokahem-12">

                      <div class="bokahem-field-name-block" id="bokahem_phone">
                            <span>
                              {{ widgetFields.order_phone.name }} *
                            </span>
                      </div>

                        <div class="info-input-holder" ref="order_phone">
                            <input ref="order_phone" type="tel" v-model="order.order_phone"
                                   :placeholder="widgetFields.order_phone.placeholder"
                                   @keyup="customPhoneValidate" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}"
                                   class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">

                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && !$v.order.order_phone.required">
                                {{ widgetFields.order_phone.name }} krävs
                            </span>

                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted && customValidations.order_phone === false">
                                {{ widgetFields.order_phone.name }} är inte giltig
                            </span>
                        </div>
                    </div>

                    <div class="col-bokahem-12" v-if="pluginSettings.supplier_sms == 1">
                        <div class="checkbox-label-row-bokahem">
                            <label class="common-checkbox-label-bokahem mr-1"
                                   :class="{'common-checkbox-label-bokahem_active': order.order_sms === true }">
                                <input type="radio" class="common-checkbox" id="callMessage">
                                <span class="common-custom-checkbox" @click="setSms()" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}">
                          <span class="common-custom-checkbox_after" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}"></span>
                        </span>
                            </label>
                            <label for="callMessage" class="call-message-label checkbox-label_col-bokahemor font-weight-medium-bokahem">
                                {{ widgetFields.order_sms.name}}
                            </label>
                        </div>
                    </div>

                    <div class="col-bokahem-12">
                        <div class="checkbox-label-row-bokahem check_border_bokahem pb-2">
                            <label class="common-checkbox-label-bokahem mr-1"
                                   :class="{'common-checkbox-label-bokahem_active': showInvoice === true }">
                                <input type="radio" class="common-checkbox" id="callAddress">
                                <span class="common-custom-checkbox" @click="setShowInvoice()" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}">
                          <span class="common-custom-checkbox_after" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}"></span>
                        </span>
                            </label>
                            <label for="callAddress" class="call-message-label checkbox-label_col-bokahemor">Annan
                                faktureringsadress?</label>
                        </div>
                    </div>

                    <div class="col-bokahem-12" v-if="showInvoice">
                    <fieldset class="form-group-bokahem check_border_bokahem">
                        <div style="display:block !important; position: initial !important;" class="font-weight-medium-bokahem mb-0">Faktureringsadress</div>
                        <div class="col-bokahem-12">

                            <div class="bokahem-field-name-block">
                                <span>
                                  {{ widgetFields.invoice_address.name }} *
                                </span>
                            </div>

                            <div class="info-input-holder" id="bokahem_invoice_address">
                                <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="invoice_address" type="text" v-model="invoiceAddress.invoice_address"
                                       :placeholder="widgetFields.invoice_address.placeholder"
                                       class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                                <span class="bokahem-valid-error"  v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                && showInvoice && !$v.invoiceAddress.invoice_address.required">
                                    {{ widgetFields.invoice_address.name }} krävs
                                </span>
                            </div>
                        </div>

                        <div class="col-bokahem-12" >

                          <div class="bokahem-field-name-block">
                                <span>
                                  {{ widgetFields.invoice_co_address.name }}
                                </span>
                          </div>

                          <div class="info-input-holder">
                                <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" type="text" v-model="invoiceAddress.invoice_co_address"
                                       :placeholder="widgetFields.invoice_co_address.placeholder"
                                       class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem font-weight-medium-bokahem">
                            </div>
                        </div>

                        <div class="col-bokahem-12" v-if="showInvoice" style="margin-bottom: 20px;">
                            <div class="row-bokahem no-gutters">
                                <div class="col-bokahem-4">
                                    <div class="bokahem-field-name-block">
                                      <span>
                                        {{ widgetFields.invoice_postal_code.name }} *
                                      </span>
                                    </div>

                                    <div class="info-input-holder" id="bokahem_invoce_postal_code">
                                        <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="invoice_postal_code" type="text" v-model="invoiceAddress.invoice_postal_code" name="zip"
                                               :placeholder="widgetFields.invoice_postal_code.placeholder"
                                               class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem--left font-weight-medium-bokahem">
                                        <span class="bokahem-valid-error"  v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                        && !$v.invoiceAddress.invoice_postal_code.required">
                                             {{ widgetFields.invoice_postal_code.name }} krävs
                                        </span>
                                    </div>
                                </div>
                                <div class="col-bokahem-8">
                                    <div class="bokahem-field-name-block">
                                        <span>
                                          {{ widgetFields.invoice_city.name }} *
                                        </span>
                                    </div>
                                    <div class="info-input-holder" id="bokahem_invoice_city">
                                        <input v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" ref="invoice_city" type="text" v-model="invoiceAddress.invoice_city"
                                               :placeholder="widgetFields.invoice_city.name"
                                               class="input-element-bokahem info-block-input-bokahem border-rounded-bokahem--right border-left--none font-weight-medium-bokahem">
                                        <span class="bokahem-valid-error"  v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                        && !$v.invoiceAddress.invoice_city.required">
                                            {{ widgetFields.invoice_city.name }} krävs
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    </div>

                    <div class="col-bokahem-12">
                        <div class="label-group-list-bokahem text-black-bokahem check_border_bokahem font-weight-medium-bokahem">
                            <label class="text-uppercase-bokahem">{{ widgetFields.order_rut.name }}:</label>

                            <label class="common-checkbox-label-bokahem"
                                   v-bind:key="index"
                                   v-for="(value,index) in widgetFields.order_rut.options"
                                   :class="{'common-radio-label_active': order.order_rut === value }">
                                <input type="radio" name="rutavdrag" class="common-radio">
                                <span class="common-custom-radio mr-2" @click="setRutavDrag(value)"  v-bind:style="{ borderColor: primaryMediumColor + ' !important'}">
                                    <span class="common-custom-radio_after"  v-bind:style="{ backgroundColor: primaryMediumColor + ' !important'}"></span>
                                </span>
                                {{ value }}
                            </label>
                        </div>

                        <fieldset id="bokahem_key_handling" class="form-group-bokahem check_border_bokahem" v-if="widgetFields !== null">
                            <div style="display:block !important; position: initial !important;" class="font-weight-medium-bokahem mb-1">{{ widgetFields.order_key_handling.name}} *</div>
                            <div id="order_key_handling_block" class="checkbox-label-row-bokahem" v-bind:key="index" v-for="(value,index) in widgetFields.order_key_handling.options">
                                <label class="common-checkbox-label-bokahem mr-1"
                                       :class="{'common-checkbox-label-bokahem_active': order.order_key_handling === value }">
                                    <input type="radio" name="nycklar" id="nycklar1" class="common-checkbox">
                                    <span class="common-custom-checkbox" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}" @click="setKeyHandling(value)">
                            <span class="common-custom-checkbox_after" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}"></span>
                          </span>
                                </label>
                                <label for="nycklar1" class="font-weight-medium-bokahem">{{ value }}</label>
                            </div>
                            <span class="bokahem-valid-error" v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                        && !$v.order.order_key_handling.required">
                                            {{ widgetFields.order_key_handling.name }} krävs
                                        </span>
                        </fieldset>
                    </div>

                    <div class="col-bokahem-12">
                        <fieldset id="bokahem_apartment_type" class="form-group-bokahem check_border_bokahem" v-if="widgetFields !== null">
                            <div style="display:block !important; position: initial !important;" class="font-weight-medium-bokahem mb-0">
                                {{ widgetFields.order_type_appartment.name }} *
                            </div>
                            <div class="label-group-list-bokahem" id="order_type_appartment_block">
                                <label class="common-checkbox-label-bokahem font-weight-medium-bokahem" v-bind:key="index"
                                       :class="{'common-checkbox-label-bokahem_active': order.order_type_appartment === value }"
                                       v-for="(value,index) in widgetFields.order_type_appartment.options">
                                    <input ref="order_type_appartment" type="radio" name="rutavdrag" class="common-checkbox">
                                    <span class="common-custom-checkbox mr-1" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}" @click="setTypeAppartment(value)">
                              <span class="common-custom-checkbox_after" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}"></span>
                            </span>{{ value}}
                                </label>
                            </div>

                            <span class="bokahem-valid-error"
                                  v-bind:style="{ color: validationColor + ' !important'}"
                                  v-if="submitted
                                        && !$v.order.order_type_appartment.required">
                                            {{ widgetFields.order_type_appartment.name }} krävs
                                        </span>

                          <div class="bokahem-field-name-block mb-0" style="height: auto;">
                                        <span>
                                          {{ widgetFields.order_additional_appartment_info.name }}
                                        </span>
                          </div>

                            <textarea v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" v-model="order.order_additional_appartment_info" class="textarea-element-bokahem textarea-bokahem border-rounded-bokahem text-black-bokahem font-weight-medium-bokahem" id="importantly"
                                      :placeholder="widgetFields.order_additional_appartment_info.placeholder" rows="4"></textarea>
                        </fieldset>


                        <fieldset class="form-group-bokahem check_border_bokahem">
                            <div style="display:block !important; position: initial !important;" class="font-weight-medium-bokahem mb-0">Uppdragsinformation</div>

                          <div class="bokahem-field-name-block mb-0" style="height: auto;">
                              <span>
                                {{ widgetFields.order_mission_description.name }}
                              </span>
                          </div>

                          <textarea v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" v-model="order.order_mission_description" class="textarea-element-bokahem textarea-bokahem border-rounded-bokahem text-black-bokahem font-weight-medium-bokahem"
                            :placeholder="widgetFields.order_mission_description.placeholder" rows="4"></textarea>
     

                            <div class="bokahem-field-name-block mb-0" style="height: auto;" v-if="isInternalPlugin == 1">
                              <span>
                                Intern kommentar
                              </span>
                          </div>

                          <textarea v-if="isInternalPlugin == 1" v-bind:style="{ backgroundColor: backgroundPrimaryColor + ' !important'}" v-model="order.order_internal_comment" class="textarea-element-bokahem textarea-bokahem border-rounded-bokahem text-black-bokahem font-weight-medium-bokahem"
                            placeholder="Visas ej för kund" rows="4"></textarea>
     
                        </fieldset>

                      <fieldset class="form-group-bokahem check_border_bokahem"
                                v-if="supplier.responsible_fields.customer.value || supplier.responsible_fields.supplier.value">


                          <div class="common-prise-block bokahem-bg-gap-summary mb-1" v-bind:style="{color: textColor + ' !important', backgroundColor: backgroundSecondaryColor + ' !important'}" style="padding:20px;border-radius:8px;margin:0">
                             
                                    <div style="display:block !important;position: initial !important;" class="font-weight-bold-bokahem"
                                            v-if="supplier.responsible_fields.customer.value && supplier.responsible_fields.customer.name">
                                      {{ supplier.responsible_fields.customer.name }}:
                                    </div>

                                    <div class="mb-1 bokahem-p-row-text" v-html="supplier.responsible_fields.customer.value"></div>

                                    <div style="display:block !important; position: initial !important;" class="font-weight-bold-bokahem"
                                            v-if="supplier.responsible_fields.supplier.value && supplier.responsible_fields.supplier.value">
                                      {{ supplier.responsible_fields.supplier.name }}:
                                    </div>

                                    <div class="bokahem-p-row-text mb-1" v-html="supplier.responsible_fields.supplier.value"></div>
                          </div>


                          <div class="bokahem-p-row-text" v-if="paymentInformation !== null" v-html="paymentInformation"></div>
                          <div class="mb-1 bokahem-p-row-text" v-if="cancelInformation !== null" v-html="cancelInformation"></div>
                
                      </fieldset>

                        <div class="deal-wrap">
                            <div class="checkbox-label-row-bokahem">
                                <label class="common-checkbox-label-bokahem" :class="{'common-checkbox-label-bokahem_active': approveGdpr === true }">
                                    <input type="radio" id="deal" class="common-checkbox">
                                    <span class="common-custom-checkbox" @click="setApproveGdpr()" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}">
                            <span class="common-custom-checkbox_after" v-bind:style="{ borderColor: primaryMediumColor + ' !important', backgroundColor: backgroundPrimaryColor + ' !important'}"></span>
                          </span>
                                </label>
                                <label class="deal-label-text checkbox-label_col-bokahemor font-weight-medium-bokahem" for="deal">
                                    Jag godkänner <span @click="showAgreementModal()" class="text-underline" style="text-decoration: underline;" v-bind:style="{ color: textColor + ' !important'}">villkoren</span> och hanteringen av personuppgifter enligt GDPR.
                                </label>

                            </div>

                            <span class="bokahem-valid-error"  v-bind:style="{ color: validationColor + ' !important'}" v-if="submitted
                                        && !approveGdpr">
                                           Du måste godkänna villkoren för GDPR
                                        </span>
                        </div>
                        <button @click="createOrder()"  v-bind:style="{ backgroundColor: primaryColor + ' !important'}" class="btn-bokahem-search-button btn-bokahem btn-bokahem-primary font-weight-semi-bokahem text-white border-rounded-bokahem btn-bokahem-xs ml-auto-bokahem">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import { SET_CHECKOUT_DATA, FETCH_WIDGET_FIELDS, IS_NEW_CUSTOMER, FETCH_PERSONAL_NUMBER, CHECK_PERSONAL_NUMBER } from "@/store/actions.type";
    import CheckoutAgreement from "@/components/CheckoutAgreement";

    export default {
        mounted() {
            if (this.$store.getters.widgetFields === null) {
                this.$store.dispatch(FETCH_WIDGET_FIELDS);
            }
            if (this.$store.getters.checkoutData !== null) {

                if ('delivery' in this.$store.getters.checkoutData) {
                    this.deliveryAddress = this.$store.getters.checkoutData.delivery;
                }

                if ('invoice' in this.$store.getters.checkoutData) {
                    this.invoiceAddress = this.$store.getters.checkoutData.invoice;
                }

                if ('order' in this.$store.getters.checkoutData) {
                    this.order = this.$store.getters.checkoutData.order;
                }

                this.approveGdpr = true;
            } else {
                this.deliveryAddress.delivery_postal_code =  this.$store.getters.postalCode;
                this.deliveryAddress.invoice_postal_code =  this.$store.getters.postalCode;
                this.deliveryAddress.delivery_city = this.capitalizeFirstLetter(this.$store.getters.place);
                this.deliveryAddress.invoice_city = this.capitalizeFirstLetter(this.$store.getters.place);
            }
        },
        computed: {
            cancelInformation() {
              if ('cancel_information' in this.$store.getters.supplier) {
                return this.$store.getters.supplier.cancel_information;
              }
              return null;
            },
            paymentInformation() {
              if ('payment_information' in this.$store.getters.supplier) {
                if ('value' in this.$store.getters.supplier.payment_information
                    && this.$store.getters.supplier.payment_information.value != null && this.$store.getters.supplier.payment_information.value != 'null') {
                  return this.$store.getters.supplier.payment_information.value;
                }
              }
              return null;
            },
            widgetFields() {
                return this.$store.getters.widgetFields;
            },
            supplier() {
                return this.$store.getters.supplier;
            },
            postalCode() {
                return this.$store.getters.postalCode
            },
            place() {
                return this.$store.getters.place;
            },
            isInternalPlugin() {
              return this.$store.getters.isInternalPlugin;
            },
            pluginSettings() {
              return this.$store.getters.pluginSettings;
            },
            primaryColor() {
              return this.$store.getters.primaryColor;
            },
            primaryMediumColor() {
              return this.$store.getters.primaryMediumColor;
            },
            backgroundPrimaryColor() {
              return this.$store.getters.backgroundPrimaryColor;
            },
            textColor() {
              return this.$store.getters.textColor;
            },
            validationColor() {
              return this.$store.getters.validationColor;
            },
            backgroundSecondaryColor() {
              return this.$store.getters.backgroundSecondaryColor;
            },
            backgroundLightColor() {
              return this.$store.getters.backgroundLightColor;
            },
        },
        methods: {

            customPhoneValidate() {

                this.customValidations.order_phone = true;

                /*
                if (this.submitted === true) {

                    this.customValidations.order_phone = true;
                    //0712345678
                    //0046712345678
                    //+46712345678
                    const regex = /^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/g;
                    const matches = regex.exec(this.order.order_phone);

                    if (matches === null) {
                        this.customValidations.order_phone = false;
                    } else {
                        if (matches[0] !== matches['input']) {
                            this.customValidations.order_phone = false;
                        }
                    }
                }

                 */
            },
            customPersonalNumberValidate() {

              if (this.submitted === true || this.searchPersonalClick === true) {

                let postalCode = this.order.order_personal_number;

                if (this.order.order_personal_number.length == 12) {
                  let firstPart = this.order.order_personal_number.slice(0, 8);
                  let secondPart = this.order.order_personal_number.slice(8, 12);
                  postalCode = firstPart + '-' + secondPart;
                }

                this.customValidations.order_personal_number = true;

                let firstTwoLetters = postalCode.slice(0,2);

                if (firstTwoLetters != '19' && firstTwoLetters != '20') {
                  this.customValidations.order_personal_number = false;
                } else {

                  const regex = /^(\d{10}|\d{12}|\d{6}-\d{4}|\d{8}-\d{4}|\d{8} \d{4}|\d{6} \d{4})/g;
                  const matches = regex.exec(postalCode);

                  if (matches === null) {
                    this.customValidations.order_personal_number = false;
                  } else {
                    if (matches[0] !== matches['input']) {
                      this.customValidations.order_personal_number = false;
                    }
                  }

                  if (this.customValidations.order_personal_number === false) {

                    if (this.order.order_personal_number.length == 12) {
                      let secondPart = this.order.order_personal_number.slice(8, 12);

                      let firstPart = this.order.order_personal_number.slice(0, 8);

                      let postalCodeStr = firstPart + '-' + secondPart;

                      postalCodeStr = postalCodeStr.replace(' ', '');

                      const regex = /^(\d{10}|\d{12}|\d{6}-\d{4}|\d{8}-\d{4}|\d{8} \d{4}|\d{6} \d{4})/g;
                      const matches = regex.exec(postalCodeStr);

                      if (matches === null) {
                        this.customValidations.order_personal_number = false;
                      } else {

                        let matchStr = matches[0];
                        let matchInput = matches['input'];

                        if (matchStr == matchInput) {
                          this.customValidations.order_personal_number = true;
                        }
                      }
                    }
                  }
                }
              }
            },
            searchPersonalNumber() {

              this.searchPersonalClick = true;

              this.customPersonalNumberValidate();

              if (this.customValidations.order_personal_number === false) {
                return;
              }
              
              this.$store.dispatch(CHECK_PERSONAL_NUMBER, this.order.order_personal_number).then(() => {
                if (this.$store.getters.personalNumberCheck === true) {

                  this.isLoadingPn = true;
                  this.$store.dispatch(FETCH_PERSONAL_NUMBER, this.order.order_personal_number).then(() => {

                    if (!this.$store.getters.personalNumberData || (Array.isArray(this.$store.getters.personalNumberData) && this.$store.getters.personalNumberData.length === 0) || (typeof this.$store.getters.personalNumberData === 'object' && Object.keys(this.$store.getters.personalNumberData).length === 0)) {
                      this.pnNoResult = true;
                    }

                    if ('firstname' in this.$store.getters.personalNumberData) {
                      this.order.order_first_name = this.$store.getters.personalNumberData.firstname;
                      this.order.order_last_name = this.$store.getters.personalNumberData.lastname;

                      this.deliveryAddress.delivery_address = this.$store.getters.personalNumberData.address;
                      this.deliveryAddress.delivery_co_address = this.$store.getters.personalNumberData.co_address;
                      this.deliveryAddress.delivery_city = this.$store.getters.personalNumberData.city;
                      this.deliveryAddress.delivery_postal_code = this.$store.getters.personalNumberData.postalcode;
                    }
                    this.isLoadingPn = false;
                  });
                } else {
                  this.customValidations.order_personal_number = false;
                  this.isLoadingPn = false;
                 return;
                }
              });
            },
            createOrder() {

                this.submitted = true;
                this.customValidations.order_phone = true;

                if (this.showInvoice === false) {
                    this.invoiceAddress.invoice_address = this.deliveryAddress.delivery_address;
                    this.invoiceAddress.invoice_co_address = this.deliveryAddress.delivery_co_address;
                    this.invoiceAddress.invoice_postal_code = this.deliveryAddress.delivery_postal_code;
                    this.invoiceAddress.invoice_city = this.deliveryAddress.delivery_city;
                }


                this.customPersonalNumberValidate();
                if (this.customValidations.order_personal_number === false) {
                  this.$scrollTo('#checkoutform', 300, { offset: -75 });
                  return;
                }

                this.$v.$touch();

                if (this.$v.$invalid) {

                    if (this.$v.order.order_first_name.required === false) {
                        this.$scrollTo('#bokahem_first_name', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.order.order_last_name.required === false) {
                        this.$scrollTo('#bokahem_last_name', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.order.order_phone.required === false) {
                        this.$scrollTo('#bokahem_phone', 300, { offset: -10 });
                        this.$scrollTo(this.$refs.order_phone);
                        return;
                    }

                    if (this.$v.order.order_email.required === false
                        || this.$v.order.order_email.email === false) {
                      this.$scrollTo('#bokahem_email', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.order.order_personal_number.required === false) {
                      this.$scrollTo('#checkoutform', 300, { offset: -10 });
                        this.$scrollTo(this.$refs.order_personal_number);
                        return;
                    }

                    if (this.$v.order.order_key_handling.required === false) {
                        this.$scrollTo('#bokahem_key_handling', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.order.order_type_appartment.required === false) {
                        this.$scrollTo('#bokahem_apartment_type', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.deliveryAddress.delivery_address.required === false) {
                      this.$scrollTo('#bokahem_delivery_address', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.deliveryAddress.delivery_city.required === false) {
                      this.$scrollTo('#bokahem_delivery_city', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.deliveryAddress.delivery_postal_code.required === false) {
                      this.$scrollTo('#bokahem_delivery_postal_code', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.invoiceAddress.invoice_address.required === false) {
                      this.$scrollTo('#bokahem_invoice_address', 300, { offset: -10 });
                        this.$scrollTo(this.$refs.invoice_address);
                        return;
                    }

                    if (this.$v.invoiceAddress.invoice_city.required === false) {
                      this.$scrollTo('#bokahem_invoice_city', 300, { offset: -10 });
                        return;
                    }

                    if (this.$v.invoiceAddress.invoice_postal_code.required === false) {
                      this.$scrollTo('#bokahem_invoice_postal_code', 300, { offset: -10 });
                        return;
                    }

                    return;
                }

                if (this.approveGdpr === false) {
                    return;
                }

                this.customPhoneValidate();
                if (this.customValidations.order_phone === false) {
                    this.$scrollTo(this.$refs.order_phone);
                    return;
                }

                let params = {};

                params['order'] = this.order;
                params['delivery'] = this.deliveryAddress;
                params['invoice'] = this.invoiceAddress;
                params['show_invoice'] = this.showInvoice;

                let paramsNewCustomerCheck = {};
                paramsNewCustomerCheck['personal_number'] = this.order.order_personal_number;
                paramsNewCustomerCheck['supplier_id'] = this.$store.getters.supplierId;

                this.$store.dispatch(IS_NEW_CUSTOMER, paramsNewCustomerCheck);

                setTimeout(() => {
                  this.$scrollTo('#headercheckouttop', 300, { offset: -75 });
                }, 50);

                this.$store.dispatch(SET_CHECKOUT_DATA, params);
            },
            setShowInvoice() {
                this.showInvoice = this.showInvoice === true ? false : true;
            },
            setRutavDrag(value) {
                this.order.order_rut = value;
            },
            setSms() {
                this.order.order_sms = this.order.order_sms === true ? false : true;
            },
            setApproveGdpr() {
                this.approveGdpr = this.approveGdpr === true ? false : true;
            },
            setKeyHandling(value) {
                this.order.order_key_handling = value;
            },
            setTypeAppartment(value) {
                this.order.order_type_appartment = value;
            },
            showAgreementModal() {
                this.$modal.show(CheckoutAgreement, {}, {
                  width: '90%',
                  maxWidth: 500,
                  adaptive: true,
                  height: 'auto',
                  scrollable: true
                });
            }
        },
        data() {
            return {
                order: {
                    order_first_name: '',
                    order_last_name: '',
                    order_phone: '',
                    order_email: '',
                    order_sms: false,
                    order_personal_number: '',
                    order_key_handling: '',
                    order_type_appartment: '',
                    order_additional_appartment_info: '',
                    order_mission_description: '',
                    order_rut: 'Ja',
                    order_internal_comment: ''
                },
                deliveryAddress: {
                    delivery_postal_code: '',
                    delivery_city: '',
                    delivery_address: '',
                    delivery_co_address: ''
                },
                invoiceAddress: {
                    invoice_postal_code: '',
                    invoice_city: '',
                    invoice_address: '',
                    invoice_co_address: '',
                },
                submitted: false,
                searchPersonalClick: false,
                showInvoice: false,
                approveGdpr: false,
                pnNoResult: false,
                isLoadingPn: false,
                customValidations: {
                    order_phone: true,
                    order_personal_number: true
                },
            }
        },
        validations: {
            order: {
                order_first_name: {
                    required
                },
                order_last_name: {
                    required
                },
                order_phone: {
                    required,
                },
                order_email: {
                    email, required
                },
                order_key_handling: {
                    required
                },
                order_personal_number: {
                    required
                },
                order_type_appartment: {
                    required
                }
            },
            deliveryAddress: {
                delivery_postal_code: {
                    required
                },
                delivery_city: {
                    required
                },
                delivery_address: {
                    required
                }
            },
            invoiceAddress: {
                invoice_postal_code: {
                    required
                },
                invoice_city: {
                    required
                },
                invoice_address: {
                    required
                }
            }
        }
    }

</script>
