<template>
    <div class="col-bokahem-12 position-end check_border_bokahem bokahem-central-position">
    <div class="row-bokahem">
      <div class="col-bokahem-12">
        <h2 class="widget-subtitle mb-0 mt-0
        bokahem-h2
        bokahem-best-gap-title
        widget-subtitle_sm
        font-weight-semi-bokahem
        text-primary
        letter-spacing_small" v-bind:style="{ color: primaryMediumColor + ' !important'}">
          Summering av beställning
        </h2>

        <div class="check text-black-bokahem">
          <div class="common-prise-block bokahem-bg-gap-summary mb-1" v-bind:style="{color: textColor + ' !important', backgroundColor: backgroundSecondaryColor + ' !important'}" style="padding:20px;border-radius:8px;margin:0">
            <h3 class="bokahem-h3" style="margin-top:0px;padding-top:0px;" v-bind:style="{color: textColor + ' !important'}">{{ gap.service_name }} </h3>
            <span class="check-subtitle font-weight-medium-bokahem">{{ gap.date_description.day_of_week_long }} kl. {{ gap.start_time }}
              <span v-if="gap.show_service_time === 1">
                ({{ gap.service_time / 60 }} tim X {{ gap.employee_quantity }} pers.)
              </span>
            </span>
            <span class="check-subtitle">Start {{ gap.date_description.day_number }} {{ gap.date_description.month }}.</span>
          </div>

          <div style="padding-left:20px;padding-right:20px;">
            <dl class="price-dl-list-bokahem">
                <div class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line"  v-bind:style="{ color: textColor + ' !important'}">
                  <dt v-bind:style="{color: textColor + ' !important'}">{{ gap.service_name_raw }}:</dt>
                  <dd v-if="serviceGroupId == 1" v-bind:style="{color: textColor + ' !important'}">{{ gap.widget_prices.service | toCurrency }}</dd>
                  <dd v-if="serviceGroupId == 2" v-bind:style="{color: textColor + ' !important'}">{{ gap.widget_prices_company.service | toCurrency }}</dd>
                </div>

              <div class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line" v-if="gap.service_startup === true && gap.prices.startup_with_vat > 0">
                <dt>Inställelseavgift:</dt>
                <dd v-bind:style="{color: textColor + ' !important'}" v-if="serviceGroupId == 1">{{ gap.widget_prices.startup | toCurrency }}</dd>
                <dd v-bind:style="{color: textColor + ' !important'}" v-if="serviceGroupId == 2">{{ gap.widget_prices_company.startup | toCurrency }}</dd>
              </div>

              <div class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line"  v-if="serviceGroupId == 2 && gap.discounts.total.value_raw > 0">
                <dt v-bind:style="{color: textColor + ' !important'}">Rabatt ({{ gap.discount_description_string}}):</dt>
                <dd v-bind:style="{color: textColor + ' !important'}">-{{ gap.widget_prices_company.rabbat | toCurrency }}</dd>
              </div>

              <div class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line" v-if="serviceGroupId == 1 && gap.discounts.total.value_raw > 0">
                <dt v-bind:style="{color: textColor + ' !important'}">Rabatt ({{ gap.discount_description_string}}):</dt>
                <dd v-bind:style="{color: textColor + ' !important'}">-{{ gap.widget_prices.rabbat | toCurrency }}</dd>
              </div>

              <div v-if="serviceGroupId == 1" class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line"  v-bind:style="{ color: textColor + ' !important'}">
                <dt v-bind:style="{color: textColor + ' !important'}">Rutavdrag:</dt>
                <dd v-bind:style="{color: textColor + ' !important'}">-{{ gap.widget_prices.rutavdrag | toCurrency }}</dd>
              </div>

              <div  v-bind:style="{ color: textColor + ' !important'}" class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line-bolder" v-if="serviceGroupId == 1">
                <dt v-if="gap.is_recurrent === true" class="font-weight-bold-bokahem" v-bind:style="{color: textColor + ' !important'}">Pris per tillfälle:</dt>
                <dt v-if="gap.is_recurrent === false" class="font-weight-bold-bokahem" v-bind:style="{color: textColor + ' !important'}">Pris:</dt>

                <dd class="font-weight-bold-bokahem" v-bind:style="{color: textColor + ' !important'}">
                  {{ gap.widget_prices.pris | toCurrency }}
                </dd>
              </div>

              <div  v-bind:style="{ color: textColor + ' !important'}" class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line-bolder" v-if="serviceGroupId == 2">
                <dt v-bind:style="{color: textColor + ' !important'}" v-if="gap.is_recurrent === true" class="font-weight-bold-bokahem">Pris per tillfälle ex moms:</dt>
                <dt v-bind:style="{color: textColor + ' !important'}" v-if="gap.is_recurrent === false" class="font-weight-bold-bokahem">Pris ex moms:</dt>

                <dd class="font-weight-bold-bokahem" v-bind:style="{color: textColor + ' !important'}">
                  {{ gap.widget_prices_company.pris_with_discount | toCurrency }}
                </dd>
              </div>

              <div class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line"  v-bind:style="{ color: textColor + ' !important'}"
                   v-if="serviceGroupId == 2 && gap.widget_prices_company.extra_description && gap.widget_prices_company.extra_part > 0">
                <dt v-bind:style="{color: textColor + ' !important'}">{{ gap.widget_prices_company.extra_description }}:</dt>
                <dd v-bind:style="{color: textColor + ' !important'}">{{ gap.widget_prices_company.extra_part | toCurrency }}</dd>
              </div>

              <div  v-bind:style="{ color: textColor + ' !important'}" class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line" v-if="serviceGroupId == 2">
                <dt v-bind:style="{color: textColor + ' !important'}">Moms, 25%:</dt>
                <dd v-bind:style="{color: textColor + ' !important'}">
                  {{ gap.widget_prices_company.moms | toCurrency }}
                </dd>
              </div>

              <div  v-bind:style="{ color: textColor + ' !important'}" class="price-dl-list-bokahem-item mb-1 bokahem-horizontal-line" v-if="gap.new_prices.discount_percent > 0">
                <dt v-bind:style="{color: textColor + ' !important'}">Rabattkod {{ campaignCode }}, {{ gap.new_prices.discount_percent }}%:</dt>
                <dd v-bind:style="{color: textColor + ' !important'}" v-if="serviceGroupId == 1">-{{ gap.widget_prices.nykundrabbat | toCurrency }}</dd>
                <dd v-bind:style="{color: textColor + ' !important'}" v-if="serviceGroupId == 2">-{{ gap.widget_prices_company.nykundsrabbat | toCurrency }}</dd>
              </div>

            </dl>
          </div>

          <div class="common-prise-block bokahem-bg-gap-summary mb-1"
               v-bind:style="{ color: textColor + ' !important', backgroundColor: backgroundSecondaryColor + ' !important'}" style="padding-left:20px;padding-right:20px;border-radius:8px;margin:0">

            <dl class="price-dl-list-bokahem">
            <div class="price-dl-list-bokahem-item mt-1 mb-1" v-if="gap.new_prices.discount_percent > 0">
              <dt v-bind:style="{color: textColor + ' !important'}" class="font-weight-bold-bokahem" v-if="serviceGroupId == 1">Pris med rabatt:</dt>
             
              <dt v-bind:style="{color: textColor + ' !important'}" class="font-weight-bold-bokahem" v-if="serviceGroupId == 2">Pris med rabatt ex moms:</dt>
             
              <dd v-bind:style="{color: textColor + ' !important'}" class="font-weight-bold-bokahem" v-if="serviceGroupId == 1">
                {{ gap.widget_prices.pris_nykundrabbat | toCurrency }}
              </dd>
              <dd v-bind:style="{color: textColor + ' !important'}" class="font-weight-bold-bokahem bokahem-margin-right-price" v-if="serviceGroupId == 2">
                {{ gap.widget_prices_company.pris_nykundsrabbat | toCurrency }}
              </dd>
            </div>

            <div class="price-dl-list-bokahem-item mt-1 mb-1 bokahem-horizontal-line" v-if="serviceGroupId == 2 && gap.new_prices.discount_percent > 0">
              <dt v-bind:style="{color: textColor + ' !important'}">Moms, 25%:</dt>
              <dd v-bind:style="{color: textColor + ' !important'}" v-if="serviceGroupId == 2">{{ gap.widget_prices_company.moms_nykundsrabbat | toCurrency }}</dd>
            </div>
            </dl>
          </div>

          <div v-if="campaignCodeDiscount === null" 
              style="max-width:580px;padding-right:20px;padding-left:20px;"  v-bind:style="{ color: textColor + ' !important'}">
              <h3 class="bokahem-h3" style="margin-top:0px;padding-top:0px;" v-bind:style="{color: textColor + ' !important'}">
                Ange rabattkoden för att få din rabatt.
              </h3>
          </div>

          <CampaignCode />
          
          <div v-if="campaignCodeDiscount !== null" 
            style="max-width:580px;padding-right:20px;padding-left:20px;"  v-bind:style="{ color: textColor + ' !important'}">
            <p class="mt-1" style="padding-bottom:0px;">
              Rabattkod <b>{{ campaignCode }}</b> används med <b>{{ campaignCodeDiscount }}</b> rabatt.
            </p>
            <p style="padding-bottom:0px;" class="mt-1" v-if="campaignCodeTerms != null && campaignCodeTerms != ''">
              {{ campaignCodeTerms }}
            </p>
          </div>

          <div style="max-width:580px;padding-right:20px;padding-left:20px;"  v-bind:style="{ color: textColor + ' !important'}">
            <p v-bind:style="{color: textColor + ' !important'}" style="font-size:16px !important" class="mt-1">
              Detta är en standardtjänst. Pristillägg kan förekomma om angiven information
              inte är korrekt eller om omständigheterna avviker.
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>

</template>

<script>

    import CampaignCode from "@/components/CampaignCode";

    export default {
        components: {
          CampaignCode
        },
        computed: {
            serviceObserver() {
                if ('service_observer' in this.$store.getters.selectedGap) {
                    if (this.$store.getters.selectedGap.service_observer !== null) {
                        if ('name' in this.$store.getters.selectedGap.service_observer
                            && 'value' in this.$store.getters.selectedGap.service_observer) {
                            if (this.$store.getters.selectedGap.service_observer.name
                                && this.$store.getters.selectedGap.service_observer.value) {
                                return this.$store.getters.selectedGap.service_observer;
                            }
                        }
                    }
                }

                return null;
            },
            primaryMediumColor() {
              return this.$store.getters.primaryMediumColor;
            },
            campaignCode() {
              return this.$store.getters.campaignCode;
            },
            campaignCodeDiscount() {
              return this.$store.getters.campaignCodeDiscount;
            },
            campaignCodeTerms() {
              return this.$store.getters.campaignCodeTerms;
            },
            backgroundSecondaryColor() {
              return this.$store.getters.backgroundSecondaryColor;
            },
            backgroundLightColor() {
              return this.$store.getters.backgroundLightColor;
            },
            textColor() {
              return this.$store.getters.textColor;
            },
            gap() {
                return this.$store.getters.selectedGap;
            },
            serviceGroupId() {
              return this.$store.getters.serviceGroupId;
            },
            discountTerms() {
                return this.$store.getters.newCustomerDiscountTerms;
            },
            supplierId() {
              return this.$store.getters.supplierId;
            },
            service() {
                return this.$store.getters.service;
            },
            recurrence() {
                return this.$store.getters.selectedRecurrenceOption
            }
        }
    }
</script>
